import { cva, VariantProps } from "class-variance-authority";
import { ComponentProps, isValidElement, PropsWithChildren } from "react";
import { cn } from "@/common/utils/css.utils";

const LabelStyle = cva(
  "flex items-center gap-1.5 rounded-full px-2 py-1 font-title text-sm",
  {
    variants: {
      color: {
        burst: "",
        curious: "",
        brand: "",
        attention: "",
      },
      appearance: {
        light: "",
        solid: "",
      },
    },
    compoundVariants: [
      {
        color: "burst",
        appearance: "light",
        className: "bg-burst-100 text-burst-900",
      },
      {
        color: "burst",
        appearance: "solid",
        className: "bg-burst-900 text-white",
      },
      {
        color: "curious",
        appearance: "light",
        className: "bg-curious-50 text-curious-800",
      },
      {
        color: "curious",
        appearance: "solid",
        className: "bg-curious-800 text-white",
      },
      {
        color: "brand",
        appearance: "light",
        className: "bg-flavour-100 text-brand-500",
      },
      {
        color: "brand",
        appearance: "solid",
        className: "bg-brand-500 text-white",
      },
      {
        color: "attention",
        appearance: "light",
        className: "bg-attention-50 text-attention-800",
      },
      {
        color: "attention",
        appearance: "solid",
        className: "bg-attention-800 text-white",
      },
    ],
    defaultVariants: {
      color: "curious",
      appearance: "light",
    },
  }
);

export type InfoLabelProps = {
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
} & Omit<ComponentProps<"label">, "color"> &
  VariantProps<typeof LabelStyle>;

export const InfoLabel = ({
  color,
  appearance,
  leftIcon,
  rightIcon,
  className,
  children,
  ...props
}: PropsWithChildren<InfoLabelProps>) => {
  const renderChildren = () => {
    if (!isValidElement(children)) {
      return <span>{children}</span>;
    }

    return children;
  };

  return (
    <label
      className={cn(LabelStyle({ color, appearance }), className)}
      {...props}
    >
      {leftIcon}
      {renderChildren()}
      {rightIcon}
    </label>
  );
};
