import { DesignerItemLabel } from "@/features/designer-item/components/DesignerItemLabel";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "next-i18next";
import { cn } from "@/common/utils/css.utils";

// Components
import Link from "@whoppah/next/link";
import { ProductCardFavoriteButton } from "@/components/product/ProductCardFavoriteButton";
import ProductCardImage from "./ProductCardImage";

// Services
import { analyticsService } from "@/common/services/analytics/analytics.service";

// Static
import { SIMILAR_ROUTE } from "@/common/static/routes.static";

/**
 * @typedef {import("../../Apollo/schema").Product} Product
 * @typedef {import("type-fest").PartialDeep} PartialDeep
 *
 * @typedef {PartialDeep<Product>} ProductPartial
 */

/**
 * @typedef {object} ProductCardProps
 * @property {ProductPartial} [item]
 * @property {boolean} [preloadImage=false]
 * @property {string} [listName]
 * @property {object} [searchMeta]
 * @property {string} [searchMeta.queryId]
 * @property {string} [searchMeta.indexName]
 * @property {number} [searchMeta.page]
 * @property {number} [searchMeta.limit]
 * @property {number} [index]
 * @property {string} [className]
 * @property {() => void} [onClick]
 * @property {boolean} [showBrowseSimilar=false]
 */

/**
 * Product card.
 *
 * @type {React.FC<ProductCardProps>}
 * @return {React.ReactElement}
 */
const ProductCard = ({
  item,
  preloadFirstImage = false,
  listName = undefined,
  searchMeta = {},
  index = undefined,
  className = undefined,
  onClick = undefined,
  showBrowseSimilar = false,
}) => {
  const { t } = useTranslation();

  /**
   * Path of the product page
   */
  const path = `/products/${item.slug}`;

  /**
   * Callback for tracking the item selection.
   */
  const handleClick = useCallback(() => {
    analyticsService.trackSelectItem(
      index + 1,
      listName,
      listName,
      item,
      searchMeta
    );
    onClick?.();
  }, [index, listName, item, onClick, searchMeta]);

  /**
   * Memorising product's price information.
   */
  const {
    currentPrice,
    previousPrice,
    isLoweredInPrice,
    discountPercentage,
    bidPrice,
    isSold,
    soldPrice,
  } = useMemo(() => {
    const buyNowPrice = item?.auction?.buy_now_price;
    const originalPrice = item?.original_price;

    const buyNowPriceAmount = buyNowPrice?.amount ?? 0;
    const originalPriceAmount = originalPrice?.amount ?? buyNowPriceAmount;

    const currentPrice = buyNowPrice;

    const previousPrice =
      buyNowPriceAmount < originalPriceAmount ? originalPrice : undefined;

    const isLoweredInPrice =
      (currentPrice?.amount ?? 0) < (previousPrice?.amount ?? 0);

    let discountPercentage = 0;
    if (isLoweredInPrice) {
      discountPercentage = parseInt(
        ((previousPrice?.amount - currentPrice?.amount) /
          previousPrice?.amount) *
          100
      );
    }

    const bidPrice =
      item?.auction?.allow_bid && item?.auction?.minimum_bid
        ? item?.auction?.minimum_bid
        : undefined;

    const isSold =
      item?.auction?.state === "COMPLETED" ||
      item?.auction?.state === "RESERVED";

    const soldPrice = item?.auction?.sold_at;

    return {
      currentPrice,
      previousPrice,
      isLoweredInPrice,
      discountPercentage,
      bidPrice,
      isSold,
      soldPrice,
    };
  }, [item]);

  /**
   * Callback for rendering the bid price of an item.
   * @param bidPrice - The current bid price of the product.
   */
  const renderBidPrice = useCallback(
    bidPrice => {
      return (
        <span>
          <span className="bid-price">
            {t("common:common-price", {
              price: bidPrice,
            })}
          </span>
        </span>
      );
    },
    [t]
  );

  /**
   * Callback for rendering the price for which the item was sold.
   * @param soldPrice - The param for which the product was sold for.
   */
  const renderSoldPrice = useCallback(
    soldPrice => {
      return (
        <span>
          <span className="sold-price">
            {t("common:common-price", {
              price: soldPrice,
            })}
          </span>
        </span>
      );
    },
    [t]
  );

  /**
   * Callback for rendering the current buy price of the item.
   * @param currentPrice - The price for which the product is currently sold for.
   * @param previousPrice - The price for which the product was previously sold for.
   * @param bidPrice - The current bid price of the product.
   */
  const renderBuyNowPrice = useCallback(
    (currentPrice, previousPrice, bidPrice) => {
      const price = bidPrice ?? currentPrice;

      return (
        <span>
          {previousPrice && !bidPrice && (
            <span className="old-buy-now-price mr-1 text-[#757575] line-through">
              {t("common:common-price", {
                price: previousPrice,
              })}
            </span>
          )}
          <span className="buy-now-price">
            {t("common:common-price", {
              price,
            })}
          </span>
        </span>
      );
    },
    [t]
  );

  /**
   * Callback for rendering the price of the product.
   * Will render different prices based on the product's tate (if it has been sold, allows bidding, has discount etc...)
   */
  const renderProductPrice = useCallback(() => {
    if (isSold && soldPrice) {
      return renderSoldPrice(soldPrice);
    }

    if (bidPrice && !isLoweredInPrice) {
      return renderBidPrice(bidPrice);
    }

    if (currentPrice) {
      return renderBuyNowPrice(currentPrice, previousPrice, bidPrice);
    }

    return null;
  }, [
    currentPrice,
    previousPrice,
    renderBuyNowPrice,
    bidPrice,
    isLoweredInPrice,
    renderBidPrice,
    isSold,
    soldPrice,
    renderSoldPrice,
  ]);

  /**
   * Callback for rendering the product label.
   */
  const renderProductLabel = useCallback(() => {
    if (isSold) {
      return (
        <span className="z-10 bg-attention-500 px-2 py-1.5 font-title text-sm font-bold uppercase leading-4 tracking-widest text-white md:py-[0.875rem] md:text-base">
          {t("common:auction-state-already-sold")}
        </span>
      );
    }

    if (discountPercentage) {
      return (
        <span className="z-10 bg-flavour-500 px-2 py-1.5 font-title text-sm font-bold uppercase leading-4 tracking-widest text-white md:py-[0.875rem] md:text-base">
          -{discountPercentage}%
        </span>
      );
    }

    return null;
  }, [isSold, discountPercentage, t]);

  /**
   * NOTE: Labels are currently not used, but might be reactivated due to the future design plans. These labels are rendered using the ProductLabels component.
   * Memorising product's labels.
   */
  // const labels = useMemo(() => {
  //   return item.attributes.reduce((labels, attribute) => {
  //     if (attribute.__typename.toLocaleUpperCase() === "LABEL") {
  //       labels.push(attribute.slug);
  //     }
  //     return labels;
  //   }, []);
  // }, [item.attributes]);

  /**
   *  Callback for browsing similar products.
   */
  const handleBrowseSimilarClick = event => {
    event.stopPropagation();

    /**
     * Triggers `show_similar_item_plp` event.
     */
    analyticsService.trackShowSimilarItemPlp();
  };

  return (
    <div className={cn("product-card group relative", className)}>
      <div className="h-full cursor-pointer" onClick={handleClick}>
        <div className="flex h-full flex-col">
          <Link sourceLocale="en" href={path} prefetch={false}>
            <a className="relative flex h-32 w-full items-end md:h-64">
              {item.images[0] ? (
                <ProductCardImage
                  preloadImage={preloadFirstImage}
                  className="transition-opacity md:group-hover:opacity-0"
                  image={item.images[0]}
                  alt={item.title}
                />
              ) : (
                <div className="h-32 w-full bg-[#eeeeee] md:h-64" />
              )}
              {item.images[1] ? (
                <ProductCardImage
                  className="opacity-0 transition-opacity md:group-hover:opacity-100"
                  image={item.images[1]}
                  alt={item.title}
                />
              ) : null}
              <ProductCardFavoriteButton item={item} />
              {renderProductLabel()}
              <DesignerItemLabel
                product={item}
                className="absolute -left-2 top-4 z-10"
              />
            </a>
          </Link>
          <div className="relative flex flex-1 flex-col bg-white">
            <Link sourceLocale="en" href={path} prefetch={false}>
              <a className="line-clamp-1 p-2 pb-[2px] font-title text-sm font-bold text-[#282828] md:line-clamp-2 md:p-4 md:pb-1 md:text-base">
                {item.title}
              </a>
            </Link>
            <Link sourceLocale="en" href={path} prefetch={false}>
              <a className="flex flex-1 flex-col justify-end p-2 pt-[2px] font-body text-sm md:p-4 md:pt-1 md:text-base">
                {renderProductPrice()}
              </a>
            </Link>
            {showBrowseSimilar && (
              <Link href={`${SIMILAR_ROUTE}/${item.slug}`}>
                <a
                  onClick={handleBrowseSimilarClick}
                  className="ml-auto hidden pb-4 pr-4 text-sm underline opacity-0 md:block md:group-hover:opacity-100"
                >
                  {t("common:common-browse-similar")}
                </a>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
