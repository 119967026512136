import { ProductUnknown } from "@/common/contracts/product.contracts";
import { InfoLabel } from "@/ui/cva/InfoLabel";
import { useTranslation } from "next-i18next";

type DesignerItemLabelProps = {
  product?: ProductUnknown;
  className?: string;
};

export const DesignerItemLabel = ({
  product,
  className,
}: DesignerItemLabelProps) => {
  const { t } = useTranslation();

  if (!product?.isDesignerItem) {
    return null;
  }

  return (
    <InfoLabel color="attention" appearance="solid" className={className}>
      {t("common:common-designer-item")}
    </InfoLabel>
  );
};
